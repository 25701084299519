import dayjs from 'dayjs';
import { DiffParams, DurationParams, DurationData } from './interfaces';

export class Duration {
  private readonly dayjs: typeof dayjs;

  constructor(dayjsExternal: typeof dayjs) {
    this.dayjs = dayjsExternal;
  }

  /**
   * Format in ISO 8601 period string
   * @param durationData
   * @returns {string}
   *
   * @example
   * ```typescript
   * Duration.toISOPeriodString({
   *  years: 2,
   * });
   * >>> 'P2Y'
   *
   * Duration.toISOPeriodString({
   *  hours: 22,
   * });
   * >>> 'PT22H'
   *
   * Duration.toISOPeriodString({
   *  years: 2,
   *  months: 11,
   *  days: 60,
   *  hours: 40,
   *  minutes: 20,
   *  seconds: 30,
   *  milliSeconds: 500
   * });
   * >>> 'P2Y11M60DT40H20M30.5S'
   * ```
   */
  public static toISOPeriodString(durationData: DurationData): string {
    return dayjs.duration(durationData).toISOString();
  }

  /**
   * get difference between two time values
   * @param {DiffParams} params
   * @returns {number} defaults to milliseconds when no unit provided
   *
   * @example
   * ```typescript
   * const dateTimeFrom = "2022-01-01T00:00:00.000Z";
   * const dateTimeTo = "2022-01-01T00:00:01.000Z";
   *
   * const result = new RSIDateTime().getDiff({
   *   startTime: dateTimeFrom,
   *   endTime: dateTimeTo,
   * })
   * // returns 1000;
   * ```
   *
   * @example
   * ```typescript
   * const dateTimeFrom = "2022-01-01T00:00:00.000Z";
   * const dateTimeTo = "2022-01-01T00:00:01.000Z";
   *
   * const result = new RSIDateTime().getDiff({
   *   startTime: dateTimeFrom,
   *   endTime: dateTimeTo,
   *   unit: "seconds",
   * })
   * // returns 1;
   * ```
   */
  public getDifference(params: DiffParams): number {
    return this.dayjs(params.endTime).diff(params.startTime, params?.unit);
  }

  /**
   * Get duration from either period or a range defined by startTime and endTime
   * @param {DurationParams} params
   * @returns {DurationData}
   *
   * Getting duration for a ISO 8601 Period string
   * @example
   * ```typescript
   * const duration = new RSIDateTime().getDuration({period: "P10D"});
   *
   * console.log(duration);
   * > {
   * >   years: 0,
   * >   months: 0,
   * >   days: 10,
   * >   hours: 0,
   * >   minutes: 0,
   * >   seconds: 0,
   * > }
   * ```
   *
   * Getting duration for provided ISO period containing weeks
   * @example
   * ```typescript
   * const duration = new RSIDateTime().getDuration({
   *   period: "P2W",
   * });
   *
   * console.log(duration);
   * > {
   * >   years: 0,
   * >   months: 0,
   * >   weeks: 2,
   * >   days: 0,
   * >   hours: 0,
   * >   minutes: 0,
   * >   seconds: 0,
   * > }
   * ```
   *
   * Getting duration for provided startTime and endTime
   * @example
   * ```typescript
   * const duration = new RSIDateTime().getDuration({
   *   startTime: "2022-04-05T10:00:00.000Z",
   *   endTime: "2022-05-05T10:00:00.000Z",
   * });
   *
   * console.log(duration);
   * > {
   * >   years: 0,
   * >   months: 1,
   * >   weeks: 0,
   * >   days: 1,
   * >   hours: 0,
   * >   minutes: 0,
   * >   seconds: 0,
   * > }
   * ```
   *
   * When provided all three properties
   * then it will only return value for given ISO 8601 Period string
   */
  public getDuration(params: DurationParams): DurationData {
    let weeksProvided = false;
    let calculatedDuration = this.dayjs.duration('');

    if (params?.period) {
      calculatedDuration = this.dayjs.duration(params.period);
      weeksProvided = /w/i.test(params.period);
    } else if (params?.endTime && params?.startTime) {
      const start = this.dayjs(params.startTime);
      const end = this.dayjs(params.endTime);
      calculatedDuration = this.dayjs.duration(end.diff(start));
    }

    function getWeeks(weeksToEval: number): number | undefined {
      if (Number.isNaN(weeksToEval)) {
        return undefined;
      }
      // Only return weeks when weeks were implicitly provided as part of ISO period value
      if (!weeksProvided) {
        return 0;
      }
      return weeksToEval;
    }

    function getNumber(value: unknown): undefined | number {
      return (Number.isNaN(value) ? undefined : value as number);
    }

    const years = calculatedDuration.years();
    const months = calculatedDuration.months();
    const days = calculatedDuration.days();
    const hours = calculatedDuration.hours();
    const minutes = calculatedDuration.minutes();
    const seconds = calculatedDuration.seconds();
    const weeks: number | undefined = getWeeks(calculatedDuration.weeks());

    return {
      years: getNumber(years),
      months: getNumber(months),
      days: getNumber(days),
      hours: getNumber(hours),
      minutes: getNumber(minutes),
      seconds: getNumber(seconds),
      weeks: getNumber(weeks),
    };
  }
}

export default Duration;
