import dayjs, { Dayjs, ManipulateType, UnitType } from 'dayjs';
import {
  AvailableMutation,
  DateTimeMutations, isMutationEntry,
  SupportedInput,
} from './interfaces';
import Duration from './Duration';

interface MutationParams {
  dateTime: SupportedInput | Dayjs;
  mutations?: Partial<DateTimeMutations>,
  period?: string;
}

export class Mutations {
  private readonly dayjs: typeof dayjs;

  constructor(dayjsExternal: typeof dayjs) {
    this.dayjs = dayjsExternal;
  }

  /**
   * set date/time values via mutations
   * @param {MutationParams} props
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z"
   *
   * const modifiedTime = new RSIDateTime(inputDateTime).setTime({
   *   mutations: {
   *     hours: 1,
   *   }
   * }) // returns "2022-01-01T01:00:00.000Z"
   * ```
   *
   * @example
   * ````typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z"
   *
   * const modifiedTime = new RSIDateTime(inputDateTime).setTime({
   *   mutations: {
   *     days: 12,
   *   }
   * }) // returns "2022-01-12T12:00:00.000Z"
   * ```
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z"
   *
   * const modifiedTime = new RSIDateTime(inputDateTime).setTime({
   *   mutations: {
   *     years: 2024,
   *     months: 4,
   *     days: 5,
   *     hours: 17,
   *     minutes: 6,
   *     seconds: 7,
   *     milliseconds: 8
   *   }
   * }) // returns "2024-04-05T17:06:07.008Z"
   * ```
   */
  public setTime({
    dateTime,
    mutations,
  }: Omit<Required<MutationParams>, 'period'>): Dayjs {
    if (mutations && Object.keys(mutations).length > 0) {
      return this.manipulateTime({
        method: (result, [type, value]) => result.set(type as UnitType, value),
        dateTime,
        mutations,
      });
    }

    return this.dayjs(dateTime);
  }

  /**
   * Add time to provided date/time
   * @param {MutationParams} props
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T00:00:00.000Z";
   *
   * const modifiedTime = new RSIDateTime(inputDateTime).addTime({
   *   mutations: {
   *     hours: 1,
   *   }
   * }) // returns "2022-01-01T01:00:00.000Z"
   * ```
   *
   * @example
   * ````typescript
   * const inputDateTime = "2022-01-01T00:00:00.000Z";
   *
   * const modifiedTime = new RSIDateTime(inputDateTime).addTime({
   *   mutations: {
   *     days: 1,
   *   }
   * }) // returns "2022-01-02T00:00:00.000Z"
   * ```
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T00:00:00.000Z";
   *
   * const modifiedTime = new RSIDateTime(inputDateTime).addTime({
   *   mutations: {
   *     years: 1,
   *     months: 2,
   *     days: 3,
   *     hours: 4,
   *     minutes: 5,
   *     seconds: 6,
   *     milliseconds: 789
   *   }
   * }) // returns "2023-03-04T04:05:06.789Z"
   * ```
   */
  public addTime({
    dateTime,
    mutations,
    period,
  }: MutationParams): Dayjs {
    return this.manipulateTime({
      method: (result, [type, value]) => result.add(value, type as ManipulateType),
      dateTime,
      mutations,
      period,
    });
  }

  /**
   * Subtract years/months/weeks/days/hours/minutes/seconds from provided timestamp
   * @param {MutationParams} props
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T00:00:00.000Z";
   *
   * const subtractedTime = new RSIDateTime(inputDateTime).subtractTime({
   *   mutations: {
   *     days: 1,
   *   }
   * })
   * // returns "2021-12-30T00:00:00.000Z"
   * ```
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T00:00:00.000Z";
   *
   * const subtractedTime = new RSIDateTime(inputDateTime).subtractTime({
   *   mutations: {
   *     months: 2,
   *   }
   * })
   * // returns "2021-11-01T00:00:00.000Z"
   * ```
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T00:00:00.000Z";
   *
   * const subtractedTime = new RSIDateTime(inputDateTime).subtractTime({
   *   mutations: {
   *     years: 1,
   *     months: 2,
   *     days: 3,
   *     hours: 4,
   *     minutes: 5,
   *     seconds: 6,
   *     milliseconds: 789,
   *   }
   * })
   * // returns "2020-10-28T19:54:53.211Z"
   * ```
   */
  public subtractTime({
    dateTime,
    mutations,
    period,
  }: MutationParams): Dayjs {
    return this.manipulateTime({
      method: (result, [type, value]) => result.subtract(value, type as ManipulateType),
      dateTime,
      mutations,
      period,
    });
  }

  /**
   * Need to sort mutations to always handle mutations from the longest time to the shortest period
   * due to dayjs having fixed length periods (e.g. 1M is always 30 days)
   */
  private sortMutations(a: AvailableMutation, b: AvailableMutation): number {
    const scores: {
      [key in AvailableMutation]: number;
    } = {
      years: 10,
      months: 9,
      weeks: 8,
      dates: 7,
      days: 7,
      hours: 6,
      minutes: 5,
      seconds: 4,
      milliseconds: 3,
    };
    return scores[b] - scores[a];
  }

  private manipulateTime({
    dateTime,
    method,
    mutations,
    period,
  }: {
    dateTime: SupportedInput | Dayjs;
    method: (result: Dayjs, [type, value]: [string, number]) => Dayjs;
    mutations?: Partial<DateTimeMutations>;
    period?: string;
  }) {
    let duration: Partial<DateTimeMutations> | null = null;

    /**
     * Convert period into duration object due to dayjs internal faulty logic
     * about month lengths being always 30 days
     */
    if (period) {
      duration = new Duration(this.dayjs).getDuration({ period });
    } else if (mutations && Object.keys(mutations).length > 0) {
      duration = mutations;
    }

    if (!duration) {
      return this.dayjs(dateTime);
    }

    return Object
      .entries(duration)
      .filter(
        (entry: unknown): entry is [AvailableMutation, number] => isMutationEntry(entry),
      )
      .sort((a, b) => this.sortMutations(a[0], b[0]))
      .reduce(
        method,
        this.dayjs(dateTime),
      );
  }
}

export default Mutations;
