import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  ApiConfig,
  DateFormats,
  DateFormatsConfig,
  DateTimeCalculationParams,
  DateTimeCompareParams,
  DateTimeParams,
  DateTimePeriodExtremityParams,
  DiffParams,
  DurationData,
  DurationParams,
  FormatDateTimeParams,
  isParseDateParams,
  isSupportedInput,
  ParseDateParams,
  SupportedInput,
  TimeConfig,
} from './interfaces';
import { logger } from './services';
import { KeysMap, mapKeys } from './utils/helpers';
import { Duration } from './Duration';
import { Mutations } from './Mutations';

export interface FormatOptions {
  locale?: string;
  showTextTimezone?: boolean;
  utcOffset?: number;
}

const DefaultDateFormats: Readonly<DateFormatsConfig> = {
  cageTime: 'LT ll',
  dateOfBirth: 'l',
  generalDateTime: 'l LTS z',
  largestWinDateTime: 'l',
  longDate: 'LL',
  time: 'LT z',
};

/**
 * Service that handles date/time source management.
 * It also provides functions for formatting date/time strings.
 * TODO (https://rushstreetgaming.atlassian.net/browse/PRM-4406): Remove useless object structure for params
 */
class RSIDateTime {
  private static readonly dayjs = dayjs;

  private static timezone: string = (
    !window.viewArgs?.mainConf?.useClientDateTime
    && window.viewArgs?.mainConf?.cageTimeZone
  ) || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  private static shouldUseClientTime = false;

  private static hasInitialized = false;

  private static unsubscribe: Unsubscribe;

  private dateInstance: Dayjs;

  private durationModule: Duration;

  private mutationModule: Mutations;

  /**
   * If incorrect ParseDateParams given, use current date.
   */
  constructor(dateTime?: SupportedInput | ParseDateParams) {
    this.durationModule = new Duration(RSIDateTime.dayjs);
    this.mutationModule = new Mutations(RSIDateTime.dayjs);

    if (isParseDateParams(dateTime)) {
      this.dateInstance = RSIDateTime.createFromParams(dateTime);
    } else if (isSupportedInput(dateTime)) {
      this.dateInstance = RSIDateTime.createFromSupportedInput(dateTime);
    } else {
      this.dateInstance = RSIDateTime.shouldUseClientTime
        ? RSIDateTime.dayjs()
        : RSIDateTime.dayjs().tz();
    }
  }

  /**
   * Get the object of usable date/time formats that are assignable in CMS
   * @returns an object containing key-value pairs where value equals the key
   *
   * @example
   * ```typescript
   * const dateTimeFormats = RSIDateTime.formats;
   * ```
   * dateTimeFormats will contain key-value pairs where value will be equal to key
   * {
   *   time: "time",
   *   cageTime: "cageTime",
   *   ...
   * }
   */
  public static get formats(): KeysMap<DateFormatsConfig> {
    return mapKeys<DateFormatsConfig>(DefaultDateFormats);
  }

  /**
   * Use this to get the date/time ISO 8061 string of current time in cage timezone
   * @returns {string} date/time ISO 8061 string
   *
   * @example
   * ```typescript
   * console.log(RSIDateTime.cageDateTime);
   * // > "2022-05-05T10:00:00.000Z"
   * ```
   */
  public static get cageDateTime(): string {
    const state = RSIConfigHandler.getState()?.configs?.cageTime?.data as TimeConfig;

    const currentTime = new Date().toISOString();
    return RSIDateTime.dayjs(currentTime).utcOffset(state.cageTimeOffsetInMs).toISOString();
  }

  /**
   * Use this to get the date/time ISO 8061 string of current time
   *
   * @returns {string} date/time ISO 8061 string
   *
   * @example
   * ```typescript
   * console.log(new RSIDateTime().currentTime.toISOString());
   * // > "2022-05-05T10:00:00.000Z"
   * ```
   */
  public get currentDateTime(): this {
    this.dateInstance = RSIDateTime.dayjs();
    return this;
  }

  /**
   * Get the Date instance of currently active dateInstance
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z";
   * new RSIDateTime(inputDateTime).toDate;
   * // returns Date instance of inputDateTime
   * ```
   */
  public get toDate(): Date {
    return this.dateInstance.toDate();
  }

  /**
   * The initializer function for the library
   * Should be called once, when the project is loaded
   */
  public static init() {
    if (this.hasInitialized) {
      return;
    }

    dayjs.extend(advancedFormat);
    dayjs.extend(customParseFormat);
    dayjs.extend(duration);
    dayjs.extend(isBetween);
    dayjs.extend(isoWeek);
    dayjs.extend(localizedFormat);
    dayjs.extend(timezone);
    dayjs.extend(utc);

    logger.log('Module mounted');

    /**
     * Get api config from RSIConfigHandler
     */
    this.unsubscribe = RSIConfigHandler.subscribe(['api'], ({ data }) => {
      this.setupSubscriber(data);
    });

    this.hasInitialized = true;
  }

  /**
   * Provide only first param to set active locale to be used by the global dayjs instance
   * Provide seconds param with locale data to add custom locale
   * @param {string} preset
   * @param {ILocale} object
   *
   * All locales have to be imported from CDN as well before using them anywhere.
   * Currently we only import "es" locale.
   * @example
   * ```typescript
   * // set active locale
   * RSIDateTime.addLocale("es");
   * ```
   *
   * @example
   * ```typescript
   * // add custom Locale
   * const localeObj = {/* ...actual Locale Obj Data *\/};
   * RSIDateTime.addLocale("es", localeObj);
   * ```
   */
  public static addLocale(preset: string, object?: ILocale): void {
    RSIDateTime.dayjs.locale(preset, object);
  }
  /**
   * Format in ISO 8601 period string
   * @param durationData
   * @returns {string}
   * @example
   * ```typescript
   * Duration.toISOPeriodString({
   *  years: 2,
   * });
   * >>> 'P2Y'
   *
   * Duration.toISOPeriodString({
   *  hours: 22,
   * });
   * >>> 'PT22H'
   *
   * Duration.toISOPeriodString({
   *  years: 2,
   *  months: 11,
   *  days: 60,
   *  hours: 40,
   *  minutes: 20,
   *  seconds: 30,
   *  milliSeconds: 500
   * });
   * >>> 'P2Y11M60DT40H20M30.5S'
   * ```
   */

  public static toISOPeriodString = (
    durationData: DurationData,
  ): string => Duration.toISOPeriodString(durationData);

  /**
   * Check if dateTimeFrom same as the dateTimeTo by the unit
   * Unit is default to milliseconds if not provided
   *
   * @param {DateTimeCompareParams} input - In shape {timeStamps:[dateTimeFrom, dateTimeTo], unit}
   * @return {boolean}
   *
   *
   * @example
   * ```typescript
   * const dateTimeFrom = '2022-01-01T12:00:00.000Z';
   * const dateTimeTo = '2022-01-01T12:00:00.001Z';
   * const isSameMillisecond = RSIDateTime.isSame({timeStamps: [dateTimeFrom, dateTimeTo]});
   * // returns false
   * ```
   *
   * @example
   * ```typescript
   * const dateTimeFrom = '2022-08-22T12:30:00.000Z';
   * const dateTimeTo = '2022-08-25T12:00:00.000Z';
   * const isSameWeek = RSIDateTime.isSame({timeStamps: [dateTimeFrom, dateTimeTo], unit: 'week'});
   * // returns true
   * ```
   */
  public static isSame({
    timeStamps: [dateTimeFrom, dateTimeTo],
    unit,
  }: DateTimeCompareParams): boolean {
    return RSIDateTime.dayjs(dateTimeFrom).isSame(RSIDateTime.dayjs(dateTimeTo), unit);
  }

  /**
   * create the RSIDateTime.dateInstance from provided SupportedInput
   */
  private static createFromSupportedInput(dateTime: SupportedInput) {
    let date: Dayjs;
    if (typeof dateTime === 'string' && RSIDateTime.isDateStringWithoutTime(dateTime)) {
      date = RSIDateTime.shouldUseClientTime
        ? RSIDateTime.dayjs(dateTime)
        : RSIDateTime.dayjs.tz(dateTime);
    } else {
      date = RSIDateTime.shouldUseClientTime
        ? RSIDateTime.dayjs(dateTime)
        : RSIDateTime.dayjs(dateTime).tz();
    }
    return date;
  }

  /**
   * create the RSIDateTime.dateInstance from provided ParseDateParams
   */
  private static createFromParams(params: ParseDateParams) {
    if (params.pattern && params.strict) {
      return RSIDateTime.shouldUseClientTime
        ? RSIDateTime.dayjs(params.dateTime, params.pattern, params.strict)
        : RSIDateTime.dayjs(params.dateTime, params.pattern, params.strict).tz();
    }
    if (params.pattern) {
      return RSIDateTime.shouldUseClientTime
        ? RSIDateTime.dayjs(params.dateTime, params.pattern)
        : RSIDateTime.dayjs.tz(params.dateTime, params.pattern, RSIDateTime.timezone);
    }
    return RSIDateTime.shouldUseClientTime
      ? RSIDateTime.dayjs(params.dateTime)
      : RSIDateTime.dayjs(params.dateTime).tz();
  }

  /**
   * Get api, language and cageTime config from RSIConfigHandler
   */
  private static setupSubscriber({
    apiUrl,
    cageCode,
    useClientDateTime,
    cageTimeZone,
  }: ApiConfig) {
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    const targetConfigs: any[] = [
      'language',
      'dateFormats',
    ];

    if (useClientDateTime) {
      this.handleTimeConfigUpdate({
        cageTimeZone,
        useClientDateTime,
      });
    } else {
      targetConfigs.push(
        {
          configKey: 'cageTime',
          customUrl: `${apiUrl}service/account/time/${cageCode}`,
        },
      );
    }

    this.unsubscribe = RSIConfigHandler.subscribe(
      targetConfigs,
      ({
        configKey,
        data,
        error,
      }) => {
        if (configKey === 'cageTime') {
          this.handleTimeConfigUpdate({
            cageTimeZone: !error ? data.cageTimeZone : cageTimeZone,
            useClientDateTime,
          });
        }
        /**
         * dateFormats config is handled in formatDateTime,
         * when dateFormats config is present then it will be used,
         * otherwise default formats will be used
         */
      },
    );
  }

  private static handleTimeConfigUpdate(
    timeConfig: { cageTimeZone: string, useClientDateTime: boolean },
  ) {
    RSIDateTime.timezone = timeConfig.cageTimeZone;
    RSIDateTime.shouldUseClientTime = timeConfig.useClientDateTime;
    if (!RSIDateTime.shouldUseClientTime) {
      dayjs.tz.setDefault(timeConfig.cageTimeZone);
    }
  }

  /**
   * Type guard for DateFormats
   * @param {any} format
   * @private
   * @returns {boolean}
   */
  private static isDefaultDateFormats(format: unknown): format is DateFormats {
    return Object.keys(RSIDateTime.formats)
      .includes(format as any);
  }

  /**
   * Check if the date/time string consists only of date
   */
  private static isDateStringWithoutTime(dateString?: string): boolean {
    return !!dateString && (dateString.indexOf('T') === -1 || dateString.length < 11);
  }

  /**
   * get difference between two time values
   *
   * @example
   * ```typescript
   * const dateTimeFrom = "2022-01-01T00:00:00.000Z";
   * const dateTimeTo = "2022-01-01T00:00:01.000Z";
   *
   * const result = new RSIDateTime().getDifference({
   *   startTime: dateTimeFrom,
   *   endTime: dateTimeTo,
   *   unit: "seconds",
   * })
   * // returns 1;
   * ```
   */
  public getDifference = (params: DiffParams) => this.durationModule.getDifference(params);

  /**
   * Get duration from either period or a range defined by startTime and endTime
   *
   * @example
   * Getting duration for a ISO 8601 Period string
   * ```typescript
   * const duration = new RSIDateTime().getDuration({period: "P10D"});
   *
   * console.log(duration);
   * > {
   * >   years: 0,
   * >   months: 0,
   * >   days: 10,
   * >   hours: 0,
   * >   minutes: 0,
   * >   seconds: 0,
   * > }
   * ```
   *
   * @example
   * Getting duration for provided ISO period containing weeks
   * ```typescript
   * const duration = new RSIDateTime().getDuration({
   *   period: "P2W",
   * });
   *
   * console.log(duration);
   * > {
   * >   years: 0,
   * >   months: 0,
   * >   weeks: 2,
   * >   days: 0,
   * >   hours: 0,
   * >   minutes: 0,
   * >   seconds: 0,
   * > }
   * ```
   *
   * @example
   * Getting duration for provided startTime and endTime
   * ```typescript
   * const duration = new RSIDateTime().getDuration({
   *   startTime: "2022-04-05T10:00:00.000Z",
   *   endTime: "2022-05-05T10:00:00.000Z",
   * });
   *
   * console.log(duration);
   * > {
   * >   years: 0,
   * >   months: 1,
   * >   weeks: 0,
   * >   days: 1,
   * >   hours: 0,
   * >   minutes: 0,
   * >   seconds: 0,
   * > }
   * ```
   */
  public getDuration = (params: DurationParams) => this.durationModule.getDuration(params);

  /**
   * Applies provided mutations to the provided date/time via substitution
   *
   * @example
   * ````typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z"
   *
   * const modifiedTime = new RSIDateTime(inputDateTime).setTime({
   *   mutations: {
   *     years: 2024,
   *     months: 4,
   *     days: 5,
   *     hours: 17,
   *     minutes: 6,
   *     seconds: 7,
   *     milliseconds: 8
   *   }
   * }) // returns "2024-04-05T17:06:07.008Z"
   * ```
   */
  public setTime = (params: Required<Omit<DateTimeCalculationParams, 'period' | 'dateTime'>>): this => {
    this.dateInstance = this.mutationModule.setTime({
      dateTime: this.dateInstance,
      mutations: params.mutations,
    });
    return this;
  };

  /**
   * Applies provided mutations to the provided date/time via addition
   *
   * @example
   * ````typescript
   * const inputDateTime = "2022-01-01T00:00:00.000Z";
   *
   * const modifiedTime = new RSIDateTime(inputDateTime).addTime({
   *   mutations: {
   *     years: 1,
   *     months: 2,
   *     days: 3,
   *     hours: 4,
   *     minutes: 5,
   *     seconds: 6,
   *     milliseconds: 789
   *   }
   * }) // returns "2023-03-04T04:05:06.789Z"
   * ```
   */
  public addTime = (params: DateTimeCalculationParams): this => {
    this.dateInstance = this.mutationModule.addTime({
      dateTime: this.dateInstance,
      mutations: params?.mutations || {},
      period: params?.period || '',
    });
    return this;
  };

  /**
   * Applies provided mutations to the provided date/time via subtraction
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T00:00:00.000Z";
   *
   * const subtractedTime = new RSIDateTime(inputDateTime).subtractTime({
   *   mutations: {
   *     years: 1,
   *     months: 2,
   *     days: 3,
   *     hours: 4,
   *     minutes: 5,
   *     seconds: 6,
   *     milliseconds: 789,
   *   }
   * })
   * // returns "2020-10-28T19:54:53.211Z"
   * ```
   */
  public subtractTime = (params: DateTimeCalculationParams): this => {
    this.dateInstance = this.mutationModule.subtractTime({
      dateTime: this.dateInstance,
      mutations: params?.mutations || {},
      period: params?.period || '',
    });
    return this;
  };

  /**
   * Check if provided date/time is a valid date/time
   * @returns {boolean}
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z"
   * new RSIDateTime(inputDateTime).isValidDateTime();
   * // returns true
   * ```
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-31-42"
   * new RSIDateTime(inputDateTime).isValidDateTime();
   * // returns true because strict mode not used
   * ```
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-31-42"
   * new RSIDateTime({
   *    dateTime: inputDateTime,
   *    pattern: 'YYYY-MM-DD',
   *    strict: true,
   * }).isValidDateTime();
   * // returns false
   * ```
   */
  public isValidDateTime(): boolean {
    return this.dateInstance.isValid();
  }

  /**
   * Set UTC offset for current instance
   * @param {number} offset
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-02-02 17:00:00 GMT+3";
   * new RSIDateTime(inputDateTime).setUTCOffset(0).toISOString();
   * // returns "2022-02-02T12:00:00Z"
   * ```
   */
  public setUTCOffset(offset: number): this {
    if (typeof offset !== 'number') {
      logger.error('Non-number UTC offset provided!');
      return this;
    }
    this.dateInstance = this.dateInstance.utcOffset(offset);
    return this;
  }

  /**
   * This functions only purpose is to add timezone to a date-only string eg "12/13/2014"
   * @param {string} dateTime
   * @returns {string} date/time ISO 8601 string
   *
   * @example
   * ```typescript
   * console.log(RSIDateTime.getDateWithTimezone("12/13/2014"));
   * // > "12"
   * ```
   *
   * @deprecated
   */
  public getDateWithTimezone(dateTime: string): this {
    const now = RSIDateTime.dayjs(RSIDateTime.dayjs());
    const dateWithTimezone = RSIDateTime.dayjs(dateTime);
    dateWithTimezone
      .set('hours', now.hour())
      .set('minutes', now.minute())
      .set('seconds', now.second())
      .set('milliseconds', now.millisecond());
    return this;
  }

  /**
   * Format date/time based on provided params
   *
   * @param {DateFormats} format The format string, preferably from format enums
   * @param {FormatOptions} options additional options
   * @returns {string} formatted date/time string
   *
   * @example
   * ```typescript
   * const formattedDateTime = new RSIDateTime(new Date()).formatDateTime({
   *    format: RSIDateTime.formats.cageTime,
   *    options: {
   *      locale: 'es',
   *    }
   * });
   * // returns "17 de marzo de 2022 GMT+2"
   * ```
   */
  public formatDateTime({
    format,
    options,
  }: FormatDateTimeParams): string {
    const { configs } = RSIConfigHandler.getState();
    const {
      default: defaultLanguage,
      locales,
    } = configs?.language?.data?.default || {};
    const {
      data: dateFormats,
    }: {
      data: DateFormatsConfig;
    } = configs?.dateFormats || DefaultDateFormats;
    /**
     * Check if provided format is key of dateFormats
     * if it is, check if dateFormats is loaded by configHandler
     * if not key of dateFormats, use as format string, defaults to localized LLL if undefined
     */
    const formatString = !RSIDateTime.isDefaultDateFormats(format)
      ? format
      : dateFormats[format];

    /**
     * TODO (PRM-4197): Add multi language support and loading all required locales
     * https://rushstreetgaming.atlassian.net/browse/PRM-4197
     *
     * We added support for providing locale if needed,
     * but currently we do not support language-switching,
     * hence no need to build this functionality yet.
     * Just read the default language and use the locale from locales if exists.
     */
    if (options?.locale) {
      const locale = options?.locale
        || locales?.find((l: any) => l.language === defaultLanguage)?.locale
        || 'en_US';
      this.dateInstance = this.dateInstance.locale(locale);
    }

    if (typeof options?.utcOffset !== 'undefined') {
      this.dateInstance = this.dateInstance.utcOffset(options.utcOffset);
    }

    const formattedDateTime = this.dateInstance.format(formatString);

    if (formattedDateTime === null) {
      throw new Error('Failed to format date/time string');
    }

    if (!options?.showTextTimezone) {
      return formattedDateTime;
    }

    return `${formattedDateTime} (${this.dateInstance.format('z')})`;
  }

  /**
   * Use to check if the provided date is today
   * @returns {boolean}
   *
   * @example
   * ```typescript
   * new RSIDateTime('2022-03-21T10:43:39.716Z').isToday();
   * // returns false
   * ```
   *
   * @example
   * ```typescript
   * new RSIDateTime().isToday();
   * // returns true
   * ```
   */
  public isToday({ dateTime }: Required<DateTimeParams>): boolean {
    this.dateInstance = RSIDateTime.dayjs(dateTime);
    return this.dateInstance
      .isSame(
        RSIDateTime.dayjs(),
        'd',
      );
  }

  /**
   * Is dateTime before timeToMatch ?
   *
   * @param {SupportedInput} params
   * @param {SupportedInput} timeToMatch
   *
   * @example
   * ```typescript
   * const inputDateTime = 1649154641930;
   * const isBefore = new RSIDateTime(inputDateTime).isBefore({
   *    timeStamps: [1649154641931]
   * });
   * // isBefore contains true, since first param is exactly 1ms less than second param
   * ```
   *
   * @example
   * ```typescript
   * const inputDateTime = 1649154641932;
   * const isBefore = new RSIDateTime(inputDateTime).isBefore({
   *    timeStamps: [1649154641931]
   * });
   * // isBefore contains false, since first param is exactly 1ms more than second param
   * ```
   */
  public isBefore({
    timeStamps: [timeToMatch],
  }: DateTimeCompareParams): boolean {
    return this.dateInstance.isBefore(timeToMatch);
  }

  /**
   * Check if a timestamp is set between other timestamps
   * @param {SupportedInput[]} timeStamps
   * @returns {boolean}
   *
   * @example
   * ```typescript
   * new RSIDateTime(111).isBetween({
   *    timeStamps: [112, 109]
   * }) // returns true
   * ```
   * @example
   * ```typescript
   * new RSIDateTime(113).isBetween({
   *    timeStamps: [112, 109]
   * }) // returns false
   * ```
   */
  public isBetween({
    timeStamps: [timeStamp1, timeStamp2],
    inclusivity,
  }: DateTimeCompareParams): boolean {
    return this.dateInstance.isBetween(timeStamp1, timeStamp2, null, inclusivity);
  }

  /**
   * Is dateTime after timeToMatch ?
   *
   * @param {SupportedInput} params
   * @param {SupportedInput} timeToMatch
   *
   * @example
   * ```typescript
   * const dateTimeFrom = 1649154641932;
   * const dateTimeTo = 1649154641931;
   * const isAfter = new RSIDateTime(dateTimeTo).isAfter({timeStamps: [dateTimeFrom]});
   * // returns false
   * ```
   *
   * @example
   * ```typescript
   * const dateTimeFrom = 1649154641932;
   * const dateTimeTo = 1649154641931;
   * const isAfter = new RSIDateTime(dateTimeFrom).isAfter({timeStamps: [dateTimeTo]});
   * // returns true
   * ```
   */
  public isAfter({
    timeStamps: [timeToMatch],
  }: DateTimeCompareParams): boolean {
    return this.dateInstance.isAfter(timeToMatch);
  }

  /**
   * Get hours of provided date/time
   * @returns {number}
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z";
   * const endOfDay = new RSIDateTime(inputDateTime).getHours();
   * // returns 12
   * ```
   */
  public getHours(): number {
    return this.dateInstance.hour();
  }

  /**
   * Get minutes of provided date/time
   * @returns {number}
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:34:00.000Z";
   * const endOfDay = new RSIDateTime(inputDateTime).getMinutes();
   * // returns 34
   * ```
   */
  public getMinutes(): number {
    return this.dateInstance.minute();
  }

  /**
   * Get seconds of provided date/time
   * @returns {number}
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:00:56.000Z";
   * const endOfDay = new RSIDateTime(inputDateTime).getSeconds();
   * // returns 56
   * ```
   */
  public getSeconds(): number {
    return this.dateInstance.second();
  }

  /**
   * Get day of provided date/time
   * @returns {number}
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z";
   * const endOfDay = new RSIDateTime(inputDateTime).getDay();
   * // returns 1
   * ```
   */
  public getDay(): number {
    return this.dateInstance.day();
  }

  /**
   * Get day of provided date/time
   * @returns {number}
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z";
   * const endOfDay = new RSIDateTime(inputDateTime).getDate();
   * // returns 1
   * ```
   */
  public getDate(): number {
    return this.dateInstance.date();
  }

  /**
   * Get day of provided date/time
   * @returns {number}
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z";
   * const endOfDay = new RSIDateTime(inputDateTime).getWeekday();
   * // returns "wednesday" for example, bot sure if this exact code returns Wednesday tho
   * ```
   */
  public getWeekday(): string {
    return this.dateInstance.format('dddd');
  }

  /**
   * Get month of provided date/time
   * @returns {number}
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z";
   * const endOfDay = new RSIDateTime(inputDateTime).getMonth();
   * // returns 1
   * ```
   */
  public getMonth(): number {
    return this.dateInstance.month();
  }

  /**
   * Get year of provided date/time
   * @returns {number}
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z";
   * const endOfDay = new RSIDateTime(inputDateTime).getYear();
   * // returns 2022
   * ```
   */
  public getYear(): number {
    return this.dateInstance.year();
  }

  /**
   * Get currently configured timezone
   * @returns {string}
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01 12:00:00 GMT+3";
   * const endOfDay = new RSIDateTime(inputDateTime).getTimeZone();
   * // returns "+03:00"
   * ```
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01 12:00:00 GMT+3";
   * const endOfDay = new RSIDateTime(inputDateTime).getTimeZone(true);
   * // returns "GMT+3"
   * ```
   */
  public getTimeZone(gmt = false): string {
    return new RSIDateTime().formatDateTime({ format: gmt ? 'Z' : 'z' });
  }

  /**
   * Converts provided Date|string|number to date/time ISO 8061 string
   * @returns {string} ISO 8601 timestamp
   *
   * @example
   * ```typescript
   * const timeStamp = RSIDateTime.toISOString(1649153073539)
   * // timeStamp will contain "2022-04-05T10:04:33.539Z"
   * ```
   */
  public toISOString(): string {
    return this.dateInstance.toISOString();
  }

  /**
   * Get the date/time in Unix timestamp milliseconds
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z";
   * const timeInMilliseconds = new RSIDateTime(inputDateTime).toMilliseconds();
   * // returns 1641038400000
   * ```
   */
  public toMilliseconds(): number {
    return this.dateInstance.valueOf();
  }

  /**
   * Get start of provided date/time specified period
   * @param dateTime
   * @param period
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z";
   * const startOfDay = new RSIDateTime(inputDateTime).getStartOfPeriod({
   *   period: "day"
   * }).toISOString();
   * // returns "2022-01-01T00:00:00.000Z"
   * ```
   */
  public getStartOfPeriod({
    period,
  }: DateTimePeriodExtremityParams): this {
    this.dateInstance = this.dateInstance.startOf(period);
    return this;
  }

  /**
   * Get end of provided date/time specified period
   * @param dateTime
   * @param period
   *
   * @example
   * ```typescript
   * const inputDateTime = "2022-01-01T12:00:00.000Z";
   * const endOfDay = new RSIDateTime(inputDateTime).getEndOfPeriod({
   *   period: "day"
   * }).toISOString();
   * // returns "2022-01-01T23:59:59.999Z"
   * ```
   */
  public getEndOfPeriod({
    period,
  }: DateTimePeriodExtremityParams): this {
    this.dateInstance = this.dateInstance.endOf(period);
    return this;
  }
}

export default RSIDateTime;
