import { DateFormats } from './LocalizedFormats';
import { FormatOptions } from '../RSIDateTime';
import { UnitType } from './DurationParams';

export type SupportedInput = Date | string | number;

export interface DateTimeParams {
  dateTime?: SupportedInput;
}

export enum InclusivityType {
  Excluded = '()',
  Included = '[]',
  IncludedStart = '[)',
  IncludedEnd = '(]',
}
export interface DateTimeCompareParams extends DateTimeParams {
  timeStamps: [SupportedInput] | [SupportedInput, SupportedInput];
  inclusivity?: InclusivityType;
  unit?: UnitType
}

export interface FormatDateTimeParams extends DateTimeParams {
  format?: DateFormats | string;
  options?: FormatOptions;
}

export interface ParseDateParams extends Required<DateTimeParams> {
  pattern?: string;
  strict?: boolean;
}

export function isSupportedInput(input: unknown): input is SupportedInput {
  return typeof input === 'string'
    || typeof input === 'number'
    || input instanceof Date;
}

export function isParseDateParams(params: unknown): params is ParseDateParams {
  return typeof params === 'object' && Object.prototype.hasOwnProperty.call(params, 'dateTime');
}
