import dayjs from 'dayjs';
import { DateTimeParams } from './DateTimeParams';

const AvailableMutations = [
  'milliseconds',
  'seconds',
  'minutes',
  'hours',
  'days',
  'dates',
  'weeks',
  'months',
  'years',
] as const;

export type AvailableMutationsTuple = typeof AvailableMutations;
export type AvailableMutation = AvailableMutationsTuple[number];

export function isMutationEntry(entry: unknown): entry is [AvailableMutation, number] {
  const [name, value]: [string, any] = entry as any;
  return AvailableMutations.includes(name as AvailableMutation) && typeof value === 'number';
}

export type DateTimeMutations = {
  [key in AvailableMutation]: number;
}

export interface DateTimeCalculationParams extends DateTimeParams {
  mutations?: Partial<DateTimeMutations>;
  period?: string;
}

export interface DateTimePeriodExtremityParams extends DateTimeParams {
  period: dayjs.UnitTypeLong,
}
