import RSIDateTime from './RSIDateTime';

const createsMultiInstanceModule = JSON.parse(
  process.env.REACT_APP_CREATES_MULTI_INSTANCE_MODULE as string,
);

if (createsMultiInstanceModule && Object.prototype.hasOwnProperty.call(RSIDateTime, 'init')) {
  RSIDateTime.init();
}

export default createsMultiInstanceModule ? RSIDateTime : new RSIDateTime();
